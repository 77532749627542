import * as React from "react";
import {graphql} from "gatsby";
import { render } from 'datocms-structured-text-to-html-string';

import StaticPageTemplate from "./../components/staticPageTemplate"

import SEO from './../components/SEO';

export const getHelp = graphql`
query getHelp {
    datoCmsHelp {
      title,
      body {
        value
        links
        blocks
      }
    }
  }
`

const HelpPage = ({data}) => {
  return (
    <StaticPageTemplate title={data.datoCmsHelp.title}>
      <SEO title="Miejsce Pamięci Dzieci Utraconych w Dębicy - pomoc" link="https://debica-dzieciutracone.pl/o-pomniku" ogTitle="Pomoc - Miejsce Pamięci Dzieci Utraconych w Dębicy" description="Oferujemy pomoc psychologiczną oraz duchowom rodzicom, którzy doświadczyli utraty swojego dziecka."/>

       <div className="DatoCMS" dangerouslySetInnerHTML={{__html: render(data.datoCmsHelp.body.value)}}></div>
    </StaticPageTemplate>
  )
}

export default HelpPage;